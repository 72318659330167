.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  &__label {
    color: var(--color-label);
    margin-bottom: 4px;
    line-height: 1;
    margin-top: -12px;
    font-size: 14px;
    align-self: flex-start;
  }
}
