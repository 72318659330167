$main-font: "AvertaPE", sans-serif;

@font-face {
  font-family: AvertaPE;
  src:
    url("~/src/assets/fonts/AvertaPE-Regular.woff") format("woff"),
    url("~/src/assets/fonts/AvertaPE-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AvertaPE;
  src:
    url("~/src/assets/fonts/AvertaPE-Semibold.woff") format("woff"),
    url("~/src/assets/fonts/AvertaPE-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


html {
  font-size: 16px;
  font-family: $main-font;
}

.tx-section-heading {
  font-size: 1.25rem;
  line-height: 1.2;
  margin: 0;
}

.tx-small {
  font-size: 0.875rem;
}

.tx-center {
  text-align: center;
}

.tx-right {
  text-align: right;
}
