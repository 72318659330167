.info-message {
  position: relative;
  padding: 20px 30px;
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #E6F8D1;
  border-radius: var(--corner-radius);

  &::before {
    content: var(--icon-info-circle);
    font-family: var(--icon-font);
    font-size: 30px;
    position: absolute;
    left: 30px;
    top: 20px;
  }

  h2 {
    margin: 0;
  }
}
