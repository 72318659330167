:root {
  --corner-radius: 10px;
  --default-shadow: 0 4px 14px 0 rgb(0 0 0 / 25%);

  --z-popups: 1000;
  --z-tooltips: 1100;
}

body {
  margin: 0;
}

p {
  margin: 0;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  color: inherit;
  font: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

input[type="submit"] {
  cursor: pointer;
}

a {
  color: var(--color-label);
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}

input {
  font: inherit;
}

hr {
  border: none;
  border-top: 1px solid var(--color-border);
  margin: 0;
}

i[class*="icon-"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.notyf {
  &__wrapper,
  &__toast {
    border-radius: var(--corner-radius) !important;
  }
}

.rcs-inner-handle {
  background-color: #888 !important;
}

.dark .rcs-inner-handle {
  background-color: white !important;
}
