.text-field {
  --field-padding: 12px;

  &__input-wrapper {
    position: relative;
  }

  &__control-button {
    position: absolute;
    right: var(--field-padding);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--color-primary);

    i {
      display: flex;
      align-items: center;
      font-size: 24px;
    }
  }

  &__input {
    border: 1px solid var(--color-border);
    padding: var(--field-padding);
    border-radius: var(--corner-radius);
    outline: none;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    font-family: inherit;
    font-size: 1rem;

    appearance: none;
    -webkit-appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: var(--color-placeholder);
      opacity: 1;
    }

    &--noborder {
      border: 0;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    margin-top: -12px;

    i { font-size: 24px; }
    
    &--prepend {
      left: var(--field-padding);
    }

    &--append {
      right: var(--field-padding);
    }
  }

  &__icon--prepend + &__input {
    padding-left: calc(var(--field-padding) * 2 + 24px);
  }

  &--has-append &__input {
    padding-right: calc(var(--field-padding) * 2 + 24px);
  }

  &--filled &__input {
    background-color: var(--color-bg-1);
    border-color: var(--color-bg-1);
  }

  &--filled &__icon {
    color: var(--color-primary);
  }

  textarea#{&}__input {
    min-height: 100px;
  }

  &--error &__input {
    border-color: var(--color-danger);
  }

  &--pending &__input {
    background-image: 
      linear-gradient(
        90deg, 
        var(--color-bg-2) 50%, 
        var(--color-bg-1) 50%, 
      );
    background-size: 200% 100%;

    animation: 1s ease-in-out text-field-stripe-movement infinite;
  }

  &:focus-within &__input {
    border-color: var(--color-primary);
  }

  &__error-label {
    border-radius: 4px;
    background-color: var(--color-danger);
    margin: 0;
    margin-top: 4px;
    color: white;
    padding: 5px;
    width: fit-content;
  }
}

@keyframes text-field-stripe-movement {
  75%, to {
    background-position: -400% 0;
  }
}

