.tooltip {
  --offset: 5px;
  --translate: 10px;

  position: relative;
  padding: 4px 12px;
  border-radius: 100px;
  position: fixed;
  color: white;
  background-color: var(--color-default);
  z-index: var(--z-tooltips);
  pointer-events: none;
  margin: 0;
  transition: 
    opacity 0.1s,
    transform 0.1s;
  opacity: 0;
  line-height: 1.25rem;

  &--right {
    margin-left: var(--offset);
    transform: translateX(calc(-1 * var(--translate)));
  }

  &--left {
    margin-right: var(--offset);
    transform: translateX(var(--translate));
  }

  &--top {
    margin-bottom: var(--offset);
    transform: translateY(var(--translate));
  }

  &--bottom {
    margin-top: var(--offset);
    transform: translateY(calc(-1 * var(--translate)));
  }

  &--active {
    opacity: 1;
    transform: none;
  }
}
