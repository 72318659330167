.document-button {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: white;
  padding: 8px 16px;

  &:hover, &--active {
    background-color: #393957;
  }

  a {
    color: inherit;
  }

  &__actions {
    display: flex;
    gap: 8px;
    padding-top: 2px;
    flex-shrink: 0;

    button {
      height: max-content;
    }
  }

  &__title {
    --title-scroll-width: 0;

    position: relative;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    flex: auto;
  }

  &:hover &__title[data-use-scroll] {
    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 4px;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, #393957 0%, rgba(#393957, 0%) 100%);
      z-index: 1;
    }

    &::after {
      left: auto;
      right: 0;
      background: linear-gradient(270deg, #393957 0%, rgba(#393957, 0%) 100%);
    }
  }

  &:hover &__title div {
    animation: docbut-scroll-title 4s ease-in-out infinite;
  }

  &__stats {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

@keyframes docbut-scroll-title {
  25%, 50% {
    transform: translateX(calc(var(--title-scroll-width) * -1));
  }

  75% {
    transform: none;
  }
}
