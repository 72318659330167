.block {
  border-radius: var(--corner-radius);
  box-shadow: var(--default-shadow);
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  gap: 16px;

  &--cols2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &--cols3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.flex {
  display: flex;
  gap: 16px;

  &--gap4 { gap: 4px; }
  &--gap8 { gap: 8px; }
  &--gap24 { gap: 24px; }
  &--gap30 { gap: 30px; }
  &--gap40 { gap: 40px; }
  &--gap48 { gap: 48px; }

  &--nogap { gap: 0; }

  &--col {
    flex-direction: column;
  }

  &--acenter {
    align-items: center;
  }

  &--astart {
    align-items: flex-start;
  }

  &--jcenter {
    justify-content: center;
  }

  &--jend {
    justify-content: flex-end;
  }

  &--jbetween {
    justify-content: space-between;
  }

  &-auto {
    flex: auto;
  }
}
