$palette: (
  'bg-1': #F5F9FD,
  'bg-2': #FFFFFF,
  'bg-3': #212134,
  'default': #12141B,
  'primary': #536CF0,
  'primary-hover': #1C3CE5,
  'secondary': #CBE7FF,
  'border': #DBE6ED,
  'input': #303752,
  'danger': #F05353,
  'danger-hover': darken(#F05353, 10%),
  'label': #5C8398,
  'placeholder': #6D6E72,
  'white': #FFFFFF,
);


:root {
  @each $key, $value in $palette {
    --color-#{$key}: #{$value};
  }
}

@each $key, $value in $palette {
  .bgc-#{$key} {
    background-color: var(--color-#{$key});
  }

  .txc-#{$key} {
    color: var(--color-#{$key});
  }
}
