.footnote {
  --gap-between-highlights: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: var(--corner-radius);
  background-color: var(--color-bg-1);
  border: 1px solid var(--color-border);
  padding: 16px 24px;
  color: var(--color-default);

  &:hover {
    color: inherit;
    opacity: 1.0;
    border-color: var(--color-primary);
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: bold;
  }

  &__state {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__highlights {
    display: flex;
    flex-direction: column;
    gap: var(--gap-between-highlights);
  }

  &__quote-wrapper:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
    padding-bottom: var(--gap-between-highlights);
  }

  &__quote {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;
  }

  &__docname-label {
    position: relative;
    display: flex;
    flex: auto;
  }

  &__fade-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    gap: 8px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      width: 32px;
      top: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(to right, rgba(0, 0, 0, 0), var(--color-bg-1));
    }
  }

  &__unfold-button {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: auto;

    &:hover,
    &:disabled {
      opacity: 0.6;
    }

    &::before {
      content: '';
      font-size: 0;
      border: 6px solid transparent;
      border-top-color: var(--color-default);
      border-bottom: 0;
    }

    &--active {
      transform: rotate(180deg);
    }
  }
}
