.folder {
  --panel-width: 340px;

  position: relative;
  display: grid;
  grid-template-columns: 1px 1fr;
  transition: grid-template-columns 0.3s;

  &--active {
    grid-template-columns: var(--panel-width) 1fr;
  }

  &__overflow-container {
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    border-right: 1px solid var(--color-border);
  }

  &__fold-button {
    --rotation-factor: 1;
    --rotation-value: 15deg;

    position: absolute;
    top: 50%;
    left: 1px;
    width: 18px;
    height: 32px;
    margin-top: -16px;
    z-index: 1;
    transition: left 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before,
    &::after {
      content: '';
      width: 2px;
      height: 14px;
      display: block;
      background-color: var(--color-bg-3);
      border-radius: 2px;
      transition: transform 0.3s;
    }

    &::before {
      transform-origin: 50% 100%;
      margin-top: 2px;
    }

    &::after {
      transform-origin: 50% 0;
      margin-top: -2px;
    }

    &:hover::before {
      transform: rotate(calc(-1 * var(--rotation-value) * var(--rotation-factor)));
    }

    &:hover::after {
      transform: rotate(calc(var(--rotation-value) * var(--rotation-factor)));
    }
  }

  &--active &__fold-button {
    left: var(--panel-width);
    --rotation-factor: -1;
  }
}

.docs-panel {
  position: relative;
  padding: 0 16px;
  padding-bottom: 16px;
  background-color: var(--color-bg-3);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  min-width: var(--panel-width);
  height: 100%;
  box-sizing: border-box;

  &__divider {
    height: 1px;
    background-color: #747B8F;
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 70px;

    h2 {
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;

      &::after {
        content: '';
        width: 32px;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0), var(--color-bg-3));
        display: block;
        position: absolute;
      }
    }
  }

  &__back-button {
    color: inherit;
    font-size: 24px;
  }

  &__list-measure-wrapper {
    flex: auto;
    position: relative;
    margin: 24px -16px;
    overflow: hidden;
  }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    list-style: none;
    padding: 0;
    margin: 0;

    [data-overlayscrollbars-contents] {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .os-scrollbar-handle {
      background-color: rgba(white, 50%);
    }
  }

  &__folder-icon {
    width: 48px;
    height: 48px;

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .icon-document {
      font-size: 48px;
    }

    .icon-cross {
      margin-top: 8px;
    }

    i {
      position: absolute;
    }

    &--pending::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid white;
      border-left-color: transparent;
      margin-top: 8px;

      animation: loading-spin 1s ease-in-out infinite;
    }
  }

  .doc-uploader {
    margin-top: auto;
  }
}

.screenshot-mode .docs-panel {
  opacity: 0.5;
  pointer-events: none;
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
