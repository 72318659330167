.avatar {
  --size: 48;

  position: relative;
  width: calc(var(--size) * 1px);
  height: calc(var(--size) * 1px);
  border-radius: 50%;
  object-fit: cover;
  font-size: calc(var(--size) / 48 * 1em);
  flex-shrink: 0;

  &:not(&--has-src) {
    background-color: var(--color-primary);
    color: white;
  }

  &::before {
    position: absolute;
    content: attr(data-initials);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
}
