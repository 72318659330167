$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("~/src/assets/fonts/icons.ttf?6381e5a67a6c294a1238b74fdc40964d") format("truetype"),
url("~/src/assets/fonts/icons.woff?6381e5a67a6c294a1238b74fdc40964d") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "zoom-out": "\f101",
    "zoom-in": "\f102",
    "v-dots": "\f103",
    "upload": "\f104",
    "trash-can": "\f105",
    "tick-circle": "\f106",
    "team": "\f107",
    "stop": "\f108",
    "slide-up": "\f109",
    "slide-right": "\f10a",
    "slide-left": "\f10b",
    "slide-down": "\f10c",
    "settings": "\f10d",
    "send": "\f10e",
    "search": "\f10f",
    "refresh": "\f110",
    "plus": "\f111",
    "plus-circle": "\f112",
    "pin": "\f113",
    "pin-filled": "\f114",
    "pin-active": "\f115",
    "paper-plane": "\f116",
    "mouse-square": "\f117",
    "minus-cirlce": "\f118",
    "message": "\f119",
    "logout": "\f11a",
    "login": "\f11b",
    "lens": "\f11c",
    "leave-team": "\f11d",
    "info-circle": "\f11e",
    "folder": "\f11f",
    "folder-filled": "\f120",
    "eye": "\f121",
    "eye-off": "\f122",
    "external-link": "\f123",
    "expand": "\f124",
    "envelope": "\f125",
    "edit": "\f126",
    "download": "\f127",
    "dots": "\f128",
    "document": "\f129",
    "document-filled": "\f12a",
    "delete": "\f12b",
    "danger": "\f12c",
    "cross": "\f12d",
    "cross-circle": "\f12e",
    "copy": "\f12f",
    "cog": "\f130",
    "close": "\f131",
    "check": "\f132",
    "arrow-up": "\f133",
    "arrow-right": "\f134",
    "arrow-left": "\f135",
    "arrow-down": "\f136",
    "arrow-down-s-line": "\f137",
    "add": "\f138",
);

.icon-zoom-out:before {
    content: map-get($icons-map, "zoom-out");
}
.icon-zoom-in:before {
    content: map-get($icons-map, "zoom-in");
}
.icon-v-dots:before {
    content: map-get($icons-map, "v-dots");
}
.icon-upload:before {
    content: map-get($icons-map, "upload");
}
.icon-trash-can:before {
    content: map-get($icons-map, "trash-can");
}
.icon-tick-circle:before {
    content: map-get($icons-map, "tick-circle");
}
.icon-team:before {
    content: map-get($icons-map, "team");
}
.icon-stop:before {
    content: map-get($icons-map, "stop");
}
.icon-slide-up:before {
    content: map-get($icons-map, "slide-up");
}
.icon-slide-right:before {
    content: map-get($icons-map, "slide-right");
}
.icon-slide-left:before {
    content: map-get($icons-map, "slide-left");
}
.icon-slide-down:before {
    content: map-get($icons-map, "slide-down");
}
.icon-settings:before {
    content: map-get($icons-map, "settings");
}
.icon-send:before {
    content: map-get($icons-map, "send");
}
.icon-search:before {
    content: map-get($icons-map, "search");
}
.icon-refresh:before {
    content: map-get($icons-map, "refresh");
}
.icon-plus:before {
    content: map-get($icons-map, "plus");
}
.icon-plus-circle:before {
    content: map-get($icons-map, "plus-circle");
}
.icon-pin:before {
    content: map-get($icons-map, "pin");
}
.icon-pin-filled:before {
    content: map-get($icons-map, "pin-filled");
}
.icon-pin-active:before {
    content: map-get($icons-map, "pin-active");
}
.icon-paper-plane:before {
    content: map-get($icons-map, "paper-plane");
}
.icon-mouse-square:before {
    content: map-get($icons-map, "mouse-square");
}
.icon-minus-cirlce:before {
    content: map-get($icons-map, "minus-cirlce");
}
.icon-message:before {
    content: map-get($icons-map, "message");
}
.icon-logout:before {
    content: map-get($icons-map, "logout");
}
.icon-login:before {
    content: map-get($icons-map, "login");
}
.icon-lens:before {
    content: map-get($icons-map, "lens");
}
.icon-leave-team:before {
    content: map-get($icons-map, "leave-team");
}
.icon-info-circle:before {
    content: map-get($icons-map, "info-circle");
}
.icon-folder:before {
    content: map-get($icons-map, "folder");
}
.icon-folder-filled:before {
    content: map-get($icons-map, "folder-filled");
}
.icon-eye:before {
    content: map-get($icons-map, "eye");
}
.icon-eye-off:before {
    content: map-get($icons-map, "eye-off");
}
.icon-external-link:before {
    content: map-get($icons-map, "external-link");
}
.icon-expand:before {
    content: map-get($icons-map, "expand");
}
.icon-envelope:before {
    content: map-get($icons-map, "envelope");
}
.icon-edit:before {
    content: map-get($icons-map, "edit");
}
.icon-download:before {
    content: map-get($icons-map, "download");
}
.icon-dots:before {
    content: map-get($icons-map, "dots");
}
.icon-document:before {
    content: map-get($icons-map, "document");
}
.icon-document-filled:before {
    content: map-get($icons-map, "document-filled");
}
.icon-delete:before {
    content: map-get($icons-map, "delete");
}
.icon-danger:before {
    content: map-get($icons-map, "danger");
}
.icon-cross:before {
    content: map-get($icons-map, "cross");
}
.icon-cross-circle:before {
    content: map-get($icons-map, "cross-circle");
}
.icon-copy:before {
    content: map-get($icons-map, "copy");
}
.icon-cog:before {
    content: map-get($icons-map, "cog");
}
.icon-close:before {
    content: map-get($icons-map, "close");
}
.icon-check:before {
    content: map-get($icons-map, "check");
}
.icon-arrow-up:before {
    content: map-get($icons-map, "arrow-up");
}
.icon-arrow-right:before {
    content: map-get($icons-map, "arrow-right");
}
.icon-arrow-left:before {
    content: map-get($icons-map, "arrow-left");
}
.icon-arrow-down:before {
    content: map-get($icons-map, "arrow-down");
}
.icon-arrow-down-s-line:before {
    content: map-get($icons-map, "arrow-down-s-line");
}
.icon-add:before {
    content: map-get($icons-map, "add");
}
