@import "~/src/assets/styles/breakpoints";

.chat {
  background-color: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 24px;
  padding: 24px;
  width: 577px;
  border-left: 1px solid var(--color-border);

  &__attachment-preview {
    position: fixed;
    transition: all 0.5s cubic-bezier(.48,-0.01,.21,1);
    object-fit: cover;
    border: 1px solid var(--color-border);
    border-radius: var(--corner-radius);
    box-sizing: border-box;
  }

  &__form {
    position: relative;
    transition: margin-top 0.3s;
    will-change: margin-top;

    &--has-attachment {
      margin-top: 60px;
    }
  }

  &__attachment {
    position: absolute;
    bottom: 100%;
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
    border-radius: var(--corner-radius);
    border: 1px solid var(--color-border);
    overflow: hidden;
    pointer-events: none;
    opacity: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      display: flex;
      align-items: center;
      justify-content: center;
      content: var(--icon-delete);
      font-family: var(--icon-font);
      color: white;
      font-size: 32px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-danger);
      opacity: 0;
      transition: 0.1s;
    }

    &:hover::after {
      opacity: 0.8;
    }
  }

  &__form--has-attachment .chat__attachment {
    opacity: 1;
    pointer-events: auto;
  }

  &__messages {
    position: relative;
    margin: 0 -24px;
    margin-top: -24px;
    flex: auto;
    overflow: hidden;
  }

  &__messages-scroll-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 24px;
    padding-bottom: 0;
    gap: 16px;
  }

  &__reverse-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    min-height: 100%;
  }

  &__send-button {
    width: 48px;
    height: 48px;
    padding: 0;
    box-sizing: border-box;
    font-size: 24px;
    position: absolute;
    right: 3px;
    top: 50%;
    margin-top: -24px;
    border-radius: calc(var(--corner-radius) - 3px);
    background-color: var(--color-primary);
    color: white;

    &:hover {
      background-color: var(--color-primary-hover);
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    i {
      transition: 0.3s ease-out;
    }

    &--pending {
      background-color: var(--color-danger);
      i {
        transform: scale(1.33);
      }

      &:hover {
        background-color: var(--color-danger-hover);
      }
    }
  }

  &__input .text-field__input {
    height: 54px;
    padding-right: 58px;
    box-shadow: var(--default-shadow);
  }
}

.screenshot-mode .chat {
  opacity: 0.5;
  pointer-events: none;
}
