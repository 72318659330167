.spinner {
  width: 100%;
  height: 8px;

  background-image: 
    linear-gradient(
      90deg, 
      var(--color-bg-1) 50%, 
      var(--color-bg-3) 50%, 
    );
  background-size: 200% 100%;

  animation: 2s ease-in-out spinner-stripe-movement infinite;
}

@keyframes spinner-stripe-movement {
  75%, to {
    background-position: -400% 0;
  }
}
