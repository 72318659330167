.select {
  display: flex;
  flex-direction: column;

  &--disabled {
    pointer-events: none;
  }

  &__dropdown {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -1px;

    .dropdown__window {
      margin-top: calc(var(--corner-radius) * -0.5);
    }
  }

  &__label {
    margin-bottom: 4px;
    margin-top: -12px;
    color: var(--color-label);
    line-height: 1;
  }

  &__trigger {
    width: 100%;
    padding: 12px;
    justify-content: space-between;
    min-width: 125px;
  }

  &[data-active=true] &__trigger {
    i {
      transform: rotate(180deg);
    }
  }
}
