.divider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 1;

  &:not(:empty) {
    gap: 10px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    content: attr(data-content);
    width: max-content;
    justify-content: center;
    background-color: var(--color-border);
    height: 1px;
    width: 100%;
  }
}
