.message {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px;
    background-color: #F5F5F5;
    border-radius: var(--corner-radius);
    margin-top: 0;
    border: 1px solid var(--color-border);

    pre {
      white-space: pre-wrap;
    }

    ol, ul {
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      gap: 8px;
      margin: 0;
    }

    table {
      text-align: left;
      border-spacing: 0;
      margin: 0 -24px;
      font-size: 0.875rem;
      width: calc(100% + 24px + 24px);

      td, th {
        padding: 8px 16px;
      }
      
      tr:nth-child(even) {
        background-color: var(--color-bg-1);
      }

      th,
      td {
        padding: 8px;
        // border-left: 1px solid var(--color-border);
        border-top: 1px solid var(--color-border);
      }

      th:last-child,
      td:last-child {
        // border-right: 1px solid var(--color-border);
        padding-right: 24px;
      }

      td:first-child,
      th:first-child {
        padding-left: 24px;
      }

      tr:last-child td {
        border-bottom: 1px solid var(--color-border);
      }
    }

    .spinner:not(:first-child) {
      margin-top: 16px;
    }

    .katex {
      & + br {
        display: none;
      }

      &-html {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        width: max-content;
        max-width: 100%;
      }

      .base {
        white-space: normal;
        width: max-content;
        max-width: 100%;
      }
    }
  }

  &__footnotes {
    transition: height 0.5s ease-out;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
  }

  &__unfold-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 24px;
    top: 16px;
    width: 24px;
    height: 24px;

    &::before {
      content: '';
      font-size: 0;
      border: 6px solid transparent;
      border-top-color: var(--color-default);
      border-bottom: 0;
    }

    &--active::before {
      transform: rotate(180deg);
    }
  }

  &__meta {
    flex: auto;
    text-align: right;
    font-size: 12px;
    color: var(--color-label);
  }

  &__attachment {
    width: 100%;
  }

  &__copy-button {
    align-self: flex-start;
  }

  &--incoming &__content {
    background-color: white;
  }
}
