.subscription-tab {
  &__properties {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 20px;
  }

  &__prop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: flex-start;

    * {
      transition: all 0.3s;
    }
  }

  &__prop-value {
    font-size: 24px;
    font-weight: 700;
  }

  &__usage {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .skeleton--active .button--link {
    display: none;
  }
}

.upgrade-plan {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  &__card {
    padding: 30px;
    border: 1px solid var(--color-border);
    border-radius: var(--corner-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    padding: 5px 10px;
    background-color: var(--color-bg-1);
    border-radius: var(--corner-radius);
  }

  &__price-label {
    font-weight: 700;
    font-size: 24px;
    margin-top: 5px;
  }

  &__features-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
