.metric {
  --progress: 0%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  &__progress {
    position: relative;
    height: 5px;
    background-color: var(--color-bg-1);

    &::before {
      position: absolute;
      content: '';
      display: block;
      left: 0;
      height: 100%;
      width: var(--progress);
      background-color: var(--color-primary);
      transition: width 1s cubic-bezier(.08,.83,.39,1.01);
    }
  }

  &__values {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:first-child {
      display: flex;
      gap: 5px;
      align-items: center;

      i {
        font-size: 24px;
      }
    }
  }
}
