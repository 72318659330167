.folders-panel {
  position: relative;
  flex: auto;

  &__scroll-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__button {
    position: relative;
    width: 48px;
    height: 48px;
    color: var(--color-default);
    font-weight: bold;

    &--active {
      pointer-events: none;
    }
    
    &::before {
      content: var(--icon-folder);
      font-family: var(--icon-font);
      font-size: 48px;
      color: var(--color-border);
    }

    &--active::before {
      content: var(--icon-folder-filled);
      color: var(--color-primary);
    }

    &::after {
      content: attr(data-initials);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 8px;
    }

    &--active::after {
      color: white;
    }
  }

  &__button-marker {
    display: flex;
    padding: 10px 0;
    width: 100%;
    align-items: center;
    justify-content: center;

    &--active {
      background-color: var(--color-bg-3);
    }
  }
}
