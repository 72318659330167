.avatar-field {
  display: flex;
  flex-direction: column;
  width: auto;

  &__label {
    margin-bottom: 4px;
    font-size: 14px;
    color: var(--color-label);
    margin-top: -12px;
  }

  input {
    display: none;
  }

  .avatar {
    --size: 128;

    cursor: pointer;
  }
}
