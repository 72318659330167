.doc-uploader {
  width: 100%;

  &__trigger {
    width: 100%;
  }

  &__dropzone {
    --bg1: var(--color-bg-1);
    --bg2: var(--color-bg-2);

    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: var(--corner-radius);
    background: var(--bg1);
    padding: 32px;
    box-sizing: border-box;
    color: var(--color-primary);
    margin-top: 24px;

    input {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: var(--corner-radius);
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23536CF0FF' stroke-width='4' stroke-dasharray='5 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      background-size: 100% calc(100% - 1px);
      background-repeat: no-repeat;
    }

    &--pending {
      background-image: linear-gradient(135deg, var(--bg1) 25%, var(--bg2) 25%, var(--bg2) 50%, var(--bg1) 50%, var(--bg1) 75%, var(--bg2) 75%, var(--bg2) 100%);
      background-size: 56.57px 56.57px;

      animation: doc-uploader-stripe 1.5s linear infinite;
      pointer-events: none;
    }
  }

  &__dropzone--dragging &__icon {
    transform: scale(1.1);
  }

  &__dropzone + &__list {
    margin-top: 16px;
  }

  &__list {
    padding: 0 24px;
    margin: 0 -24px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: 0.25s height;

    li {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &__status-icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin: -10px 0;
  }

  &__item {
    --progress: 0;
    width: 100%;

    &::after {
      width: 100%;
      display: flex;
      content: '';
      font-size: 0.8em;
      text-align: center;
      color: var(--color-danger);
      flex: auto;
      border-radius: var(--corner-radius);
      background: linear-gradient(-90deg, transparent 50%, var(--color-primary) 50%);
      background-size: 200% 100%;
      background-position: calc(100% * (1 - var(--progress))) 0;
      transition: 0.25s background-position;
      border: 1px solid var(--color-primary);
      box-sizing: border-box;
      height: 8px;
    }

    &--error::after {
      background-image: linear-gradient(-90deg, transparent 50%, var(--color-danger) 50%);
      border: 1px solid var(--color-danger);
      background-position: 0% 0;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin: 0;
    color: var(--color-input);
    font-weight: bold;
  }

  &__icon {
    font-size: 49px !important;
    transition: 0.25s transform;
    will-change: transform;
  }
}

@keyframes doc-uploader-stripe {
  100% {
    background-position: 56.57px 0;
  }
}

@keyframes doc-uploader-spin {
  100% {
    transform: rotate(360deg);
  }
}
