.workspace {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 77px 1fr;

  &__logo {
    width: 48px;
    height: 48px;
    margin: 0 auto;
  }

  &__main-sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 0;
  }
}

.screenshot-mode .workspace {
  &__main-sidebar {
    opacity: 0.5;
    pointer-events: none;
  }
}
