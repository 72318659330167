.team-management-tabs {
  .tabs__content {
    padding-top: 40px;
  }
}

.manage-members-tab {
  &__scroll-wrapper {
    max-height: 512px;
    overflow: auto;
    border-bottom: 1px solid var(--color-border);
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;

    button {
      flex-shrink: 0;
    }
  }
}
