.table {
  width: 100%;

  th {
    background: var(--color-bg-1);
    font-size: 14px;
    height: 40px;
    box-sizing: border-box;
    text-align: left;
    position: sticky;
    top: 0;
    border-top: 1px solid var(--color-border);
  }

  td, th {
    padding: 16px;
    box-sizing: border-box;

    &:first-child {
      padding-left: 16px;
      border-left: 1px solid var(--color-border);
    }

    &:last-child {
      border-right: 1px solid var(--color-border);
    }

    border-bottom: 1px solid var(--color-border);
  }
}
