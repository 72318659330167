.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-popups);

  &:not(&--no-overlay) {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
  }

  --width: 400px;

  &__window {
    width: calc(1px * var(--width, 400));
    background-color: var(--color-bg-2);
    gap: 32px;
    overflow: hidden;
    box-sizing: border-box;
    transition: height 0.5s cubic-bezier(.24,-0.01,.15,1);
  }

  &--no-shadow &__window {
    box-shadow: none;
  }

  &__close-button {
    position: relative;
    font-size: 24px;
    margin-left: auto;
    z-index: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    line-height: 1;
    margin: 0;
    font-size: 1.5rem;
  }
}
