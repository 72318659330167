.dropdown {
  padding: 16px 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 1000;

  &__window {
    background-color: white;
    border: 1px solid var(--color-border);
    border-radius: var(--corner-radius);
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    box-shadow: var(--default-shadow);
  }

  &--submenu &__window {
    margin-left: calc(var(--corner-radius) * -0.5);
  }

  &__button,
  &__header {
    padding: 10px 15px;
    width: 100%;
    justify-content: flex-start;
    box-sizing: border-box;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-border);
    }

    &--no-padding {
      padding: 0;
    }

    input {
      padding: 10px 15px;
    }
  }

  &__button {
    display: flex;
    gap: 8px;
    align-items: flex-start;

    i {
      margin-top: -3px;
      font-size: 24px;
    }

    .avatar {
      margin-top: -2px;
      margin-bottom: -6px;
    }

    &:hover,
    &[data-active=true] {
      background-color: #ECF4FB;
      color: var(--color-primary);
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__group:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
  }

  &__group &__button {
    border: 0;
  }

  &__button-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
  }

  &__submenu-icon {
    margin: auto 0;
    margin-top: auto !important;
    font-size: 12px !important;
  }
}
