.document-group {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__header {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    padding: 0 16px;

    button {
      color: #AADBFF;
    }
  }

  &__title {
    flex: auto;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}
