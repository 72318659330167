@mixin button-animation-stripes($color1, $color2) {
  background-image: 
    linear-gradient(
      90deg, 
      $color2 50%, 
      $color1 50%, 
    );
  background-size: 200% 100%;

  animation: 2s ease-in-out button-stripe-movement infinite;
}

.button {
  padding: 12px 24px;
  border-radius: var(--corner-radius);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  box-sizing: border-box;
  display: inline-flex;
  gap: 8px;
  height: max-content;
  cursor: pointer;
  transition: 0.3s;
  transition-property: background-color, color, border-color;

  i {
    font-size: 24px;
    margin: -12px 0;

    &:not(:only-child) {
      margin-left: -12px;
    }
  }

  &:hover,
  &--active {
    background-color: var(--color-bg-1);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--round {
    --diameter: 48;
    --font-size-percent: calc(var(--diameter) / 48 * 1em);

    width: calc(var(--diameter) * 1px);
    height: calc(var(--diameter) * 1px);
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-color: var(--color-border);
    color: var(--color-default);
    flex-shrink: 0;

    &[data-initials]::before {
      content: attr(data-initials);
      font-size: var(--font-size-percent);
    }

    i {
      font-size: 24px;
    }
  }

  &--gray {
    border-color: var(--color-border);
    color: var(--color-default);
  }

  &--tool,
  &--tool-icon {
    padding: 4px 12px;
    border-radius: var(--corner-radius);
    color: var(--color-default);
    border-color: white;
    background-color: white;
    min-width: 32px;
    min-height: 32px;
    text-align: center;

    appearance: none;
    -webkit-appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:hover {
      background-color: var(--color-bg-1);
      border-color: var(--color-border);
    }
  }

  &--tool-icon#{&}--active {
    border-color: var(--color-primary);
  }

  &--tool-icon {
    padding: 4px;
  }

  &--primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: white;

    &:hover {
      background-color: var(--color-primary-hover);
    }
  }

  &--danger {
    background-color: var(--color-danger);
    border-color: var(--color-danger);
    color: white;

    &:hover {
      background-color: var(--color-danger-hover);
    }
  }

  &--link {
    margin-left: -24px;
    border: none;
  }

  &--pending,
  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--pending {
    @include button-animation-stripes(var(--color-border), white);
  }

  &--pending#{&}--primary {
    @include button-animation-stripes(var(--color-primary), var(--color-primary-hover));
  }

  &--pending#{&}--danger {
    @include button-animation-stripes(var(--color-danger), var(--color-danger-hover));
  }

  &--fluid {
    width: 100%;
  }
}

@keyframes button-stripe-movement {
  75%, to {
    background-position: -400% 0;
  }
}
