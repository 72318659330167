.skeleton {
  position: relative;

  --blend-mode: color-dodge;
  --color: rgba(255, 255, 255, 0.2);

  &--light {
    --blend-mode: normal;
    --color: #f2f2f2;
  }

  p, span, i, button, input, textarea, .avatar, .field__label {
    transition: 0.3s;
    transition-property: background-color, color;
  }

  &--active {
    pointer-events: none;
    
    button {
      pointer-events: none;
    }

    p, span, i, button, input, textarea, .avatar, .field__label {
      background-color: var(--color) !important;
      mix-blend-mode: var(--blend-mode);
      animation: skeleton-anim 1.0s infinite linear alternate;
      color: transparent !important;
      border-color: transparent !important;
  
      &:not(.avatar) {
        border-radius: var(--corner-radius);
      }
  
      &::placeholder {
        color: transparent !important;
      }
  
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          animation-delay: 0.3s * $i;
        }
      }
    }

    .avatar{
      object-position: 1000% 0;
      object-fit: none;
    }

    button > i {
      background-color: transparent;
    }

    i {
      border-radius: 50%;
    }
  }
}

@keyframes skeleton-anim {
  100% {
    opacity: 0.5;
  }
}
