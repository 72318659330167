.artwork-layout {
  min-height: 100vh;

  &--with-image {
    display: grid;
    grid-template-columns: 1fr max(550px, 43vw);
  }

  &__artwork {
    height: 100vh;
    width: 100%;
    background-color: var(--color-background-1);
    object-fit: cover;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__container {
    width: 450px;
  }

  &__logo {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
}
