.checkbox {
  cursor: pointer;

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    user-select: none;
  }

  &__label::before {
    content: '';
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid var(--color-label);
    transition: 0.125s;

    background-repeat: no-repeat;
    background-position: center;
    margin: -12px 0;
  }

  &:hover &__label::before {
    background-color: var(--color-bg-1);
  }

  &__input {
    display: none;
  }

  &__input:checked + &__label::before {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    background-image: url('./checked.svg');
  }
}
