.tabs {
  &__buttons {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 8px;

    &::before {
      position: absolute;
      content: '';
      width: var(--selected-width, 0);
      left: var(--selected-left, 0);
      height: 4px;
      background-color: var(--color-primary);
      bottom: 0;
      transition: 0.5s cubic-bezier(.14,.8,.15,1);
    }
  }

  &__button {
    padding: 2px 0;
    color: var(--color-placeholder);

    &--active {
      border-color: var(--color-primary);
      font-weight: bold;
      color: var(--color-default);
    }
  }

  &__content {
    padding-top: 24px;
  }
}
