.document-view {
  display: flex;

  &__selection-box {
    position: fixed;
    pointer-events: none;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    background-color: rgba(83, 108, 240, 0.1);
    z-index: 1000;
    box-shadow: var(--default-shadow);
  }

  &__main {
    flex: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg-1);
  }
  
  &__header {
    border-bottom: 1px solid var(--color-border);
    display: flex;
    align-items: center;
    height: 70px;
    padding: 16px;
    box-sizing: border-box;
    justify-content: space-between;

    h2 {
      position: relative;
      width: 100%;

      &::before {
        content: 'W';
        color: transparent;
        pointer-events: none;
      }

      span {
        position: absolute;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &::after {
        content: '';
        width: 32px;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0), var(--color-bg-1));
        display: block;
        position: absolute;
      }
    }
  }

  &__divider {
    margin: 0 8px;
    width: 1px;
    height: 32px;
    background-color: var(--color-border);
  }

  &__zoom-button {
    width: 70px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__viewer {
    position: relative;
    flex: auto;
    overflow: hidden;
  }

  &__scroll {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .simplebar-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    &--over100 .simplebar-content {
      align-items: flex-start;
    }
  }

  &__page {
    border: 1px solid var(--color-border);
  }

  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 32px;

    i {
      color: var(--color-danger);
      font-size: 64px;
    }
  }

  &__spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &::before {
      content: 'Loading...'
    }

    .spinner {
      width: 200px;
    }
  }
}

